<template>
    <div id="App">
        <router-view></router-view>
    </div>
</template>

<script>
export default {

}
</script>

<style>
    *{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        font-family: 'Roboto', sans-serif;
        text-decoration: none;
    }
</style>